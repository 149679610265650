<template>
    <div class="wrapper productFlow">
        <table class="custom-table">
            <thead>
                <tr>
                    <td class="custom-th custom-th-bg" style="min-width:.6rem;">
                        序号
                    </td>
                    <td class="custom-th custom-th-bg" style="min-width:.6rem;">
                        品种
                    </td>
                    <td class="custom-th custom-th-bg" style="min-width:1.2rem;">
                        项目
                    </td>
                    <template v-for="(item, index) in ProductFlowData[0].flowtoReports">
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.date>0">
                            {{ item.date }}月
                        </td>
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.date===0">
                            总计
                        </td>
                    </template>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, index) in ProductFlowData">
                    <tr :key="index" :class="{'border6': index<ProductFlowData.length-1}">
                        <td class="custom-th" :class="{'custom-th-odd':index%2===0,'custom-th-even':index%2===1}" style="width:.6rem;">
                            {{ index+1 }}
                        </td>
                        <td class="custom-th">
                            {{ item.label }}
                        </td>
                        <td>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>销量（万方）</td>
                                    </tr>
                                    <tr>
                                        <td>价格（元/方）</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <template v-for="(k, i) in item.flowtoReports">
                            <td :key="i">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td @click="showDataDrills(k,'sales')" v-if="isDrill">
                                                <a href="javascript:;">{{ k.sales }}</a>
                                            </td>
                                            <td v-else>
                                                {{ k.sales }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k,'price')" v-if="isDrill">
                                                <a href="javascript:;">{{ k.price }}</a>
                                            </td>
                                            <td v-else>
                                                {{ k.price }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    // 产品流向
    name: 'product-flow',
    components: {},
    props: {
        loadObj: {
            type: [Object],
        },
        reportData: {
            type: [Object],
        },
        queryStr: {
            type: [String],
        },
    },
    data() {
        return {
            ProductFlowData: [
                {
                    flowtoReports: [],
                },
            ],
            isDrill: true, // 是否钻取 
        };
    },
    watch: {
        queryStr: function () {
            this.gitProductFlowData();
        },
    },
    computed: {},
    methods: {
        gitProductFlowData() {
            this.$axios
                .get('/interfaceApi/report/flowto/report/' + this.queryStr)
                .then(res => {
                    this.ProductFlowData = res;
                    // this.loadObj.close();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        isDrillFn() {
            if (this.reportData.org_type === 5 || this.reportData.org_type === 6) {
                this.isDrill = false;
            } else {
                this.isDrill = true;
            }
        },
        // 数据钻取
        // eslint-disable-next-line max-params
        showDataDrills(rowData, field) {
            let parseField = 0;
            let parsetText = null;
            let showType = null;
            switch (field) {
                case 'sales':
                    parseField = 1;
                    parsetText = '销量(万方)';
                    break;
                case 'price':
                    parseField = 2;
                    parsetText = '价格(元/方)';
                    showType = 1;
                    break;
                default:
                    parseField = 0;
            }
            let istotal = 0;
            let month = 0;
            if (rowData.date === 0) {
                istotal = 1;
                if (this.reportData.month === 0) {
                    month = 12;
                } else {
                    month = this.reportData.month;
                }
            } else {
                istotal = 0;
                month = rowData.date;
            }
            const params = {
                field: parseField,
                text: parsetText,
                year: this.reportData.year,
                month: month,
                business_code: 'yyqk',
                istotal: istotal,
                treeid: this.reportData.treeid,
                typecode: rowData.type_code,
                firstPop: true,
                sourceReport: 'productFlowTable',
                showType: showType,
            };
            const pop = this.$toast({
                title: false,
                t_url: 'reportManage/operationTrends/summary/dataDrills/DrillDownPop',
                type: 'eject',
                width: '80%',
                extr: { closeBtn: { width: '.6rem', height: '.6rem', 'line-height': '.6rem' }, params: params },
            });
            window.window.$globalHub.$store.commit('setChartPop', pop);
        },
    },
    created() {},
    mounted() {
        // 获取产品流向数据
        this.gitProductFlowData();
        this.isDrillFn();
    },
    updated() {
        // 获取产品流向数据
        // this.gitProductFlowData();
        // this.isDrillFn();
    },
};
</script>
<style lang="stylus">
.productFlow
    table
        table
            width 100%
            border-collapse collapse
            tr
                &:last-child
                    td
                        border none !important
            td
                border none !important
                border-bottom 1px solid #ebeef5 !important
</style>